<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">What We Do</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img style="width: 250px; height:250px" src="assets/images/yjvc.svg" alt="YJVC" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-6">
            <!-- about text -->
            <p>
              YJVC dabbles in all kinds of emerging and cutting-edge technologies. We have a ton of experience building
              complex technical solutions from ideation to delivery and consistent roadmap support. Our expertise extends from the web 
              to every other major platform: iOS, Android, TvOS, WatchOS, MacOS and Windows.
            </p>
            <!-- <div class="mt-3">
              <a href="javascript:" class="btn btn-default">Download CV</a>
            </div> -->
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <div class="col-md-6">
                        <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Cloud DevOps</h4>
                <span class="float-right">25%</span>
              </div>
              <ngb-progressbar type="warning" [value]="25"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Web/iOS/WatchOS/Android</h4>
                <span class="float-right">25%</span>
              </div>
              <ngb-progressbar type="warning" [value]="25"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">UI/UX R&D</h4>
                <span class="float-right">25%</span>
              </div>
              <ngb-progressbar type="danger" [value]="25"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">NLP/Machine Learning</h4>
                <span class="float-right">25%</span>
              </div>
              <ngb-progressbar type="primary" [value]="25"></ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- row end -->

